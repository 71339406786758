<template>
  <div class="block">
    <H5Navheader :name="'数据详情'" />
    <div class="main-body">
      <div class="block-card" v-loading="loading">
        <!-- <div class="bg-image"></div> -->
        <div class="content">
          <div class="title">数据详情</div>
          <div style="display:inline-block">
            <div class="hex-card">
              <div class="label">ID</div>
              <div class="value">{{source.id}}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">数据类型</div>
            <div class="value">{{source.typeName}}</div>
          </div>
          <div class="item">
            <div class="label">区块高度</div>
            <div class="value">{{source.blockNumber}}</div>
          </div>
          <div class="item">
            <div class="label">数据哈希</div>
            <div class="value">{{source.dataHash}}</div>
          </div>
          <div class="item">
            <div class="label">区块哈希</div>
            <div class="value">{{source.blockHash}}</div>
            <div class="copy" @click="clickCopy">复制</div>
          </div>
          <div class="item">
            <div class="label">区块时间</div>
            <div class="value">{{source.createTime}}</div>
          </div>
          <div class="item">
            <div class="label">上链时间</div>
            <div class="value">{{source.updateTime}}</div>
          </div>
        </div>
      </div>
      
      <div class="info">
        <div class="title">链上数据解析</div>
        <div class="info-list">
          <ul class="list">
            <li class="item" v-for="(value, key) in chain" :key="key">
              <div class="label">{{key}}</div>
              <div class="value">{{value}}</div>
            </li>
            <!-- <li class="item">
              <div class="label">会员id</div>
              <div class="value">146085</div>
            </li>
            <li class="item">
              <div class="label">会员信息</div>
              <div class="value">1591633701505077249</div>
            </li>
            <li class="item">
              <div class="label">当前状态</div>
              <div class="value">已收货</div>
            </li>
            <li class="item">
              <div class="label">订单编号</div>
              <div class="value">1629354802773360640-409</div>
            </li>
            <li class="item">
              <div class="label">下单时间</div>
              <div class="value">2023-02-25 13:37:12</div>
            </li>
            <li class="item">
              <div class="label">付款时间</div>
              <div class="value">2023-02-25 13:37:28</div>
            </li>
            <li class="item">
              <div class="label">收货人</div>
              <div class="value">汪**</div>
            </li>
            <li class="item">
              <div class="label">商品金额</div>
              <div class="value">998.00</div>
            </li>
            <li class="item">
              <div class="label">订单金额</div>
              <div class="value">998.95</div>
            </li>
            <li class="item">
              <div class="label">地址</div>
              <div class="value">安徽马鞍山市雨山区**5栋604</div>
            </li>
            <li class="item">
              <div class="label">电话</div>
              <div class="value">**1452</div>
            </li>
            <li class="item">
              <div class="label">物流信息</div>
              <div class="value">EMSEMS1251486414147</div>
            </li>
            <li class="goodsname">【恒睿康】蓝莓叶黄素脂黑果腺肋花楸果粉固体饮料60克*3盒_60克*3盒_1： 1</li> -->
          </ul>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import H5Navheader from '@/components/H5Navheader'
import { getSourceDetail, getSourceDetailByKeyCode } from '@/api/chain'
export default {
  components: {
    H5Navheader,
  },
  created() {
    // console.log(this.$route.query.id)
    // console.log(this.$route.query.keyCode)
    if (this.$route.query.id) {
      this.getSourceDetail()
    }
    if (this.$route.query.keyCode) {
      this.getSourceDetailByKeyCode()
    }
    // this.getDataDetail()
  },
  data() { 
    return {
      source: {},
      chain: {},
      loading: true
    }
  },
  methods:{
    getSourceDetail() {
      this.loading = true
      getSourceDetail({id:this.$route.query.id}).then(res => {
        this.source = res.data
        this.chain = JSON.parse(res.data.dataSource)
        this.loading = false
      })
    },
    getSourceDetailByKeyCode() {
      this.loading = true
      getSourceDetailByKeyCode({keyCode:this.$route.query.keyCode}).then(res => {
        this.source = res.data
        this.chain = JSON.parse(res.data.dataSource)
        this.loading = false
      })
    },
    // getDataDetail() {
    //   getDataDetail({keyCode:this.$route.query.keyCode}).then(res => {
        
    //     this.chain = res.data.blockContent
    //   })
    // },
    clickCopy() {
      const save = (e) => {
        e.clipboardData.setData('text/plain', this.source.blockHash)
        e.preventDefault() // 阻止默认行为
      }
      const once = {
        once: true
      }
      document.addEventListener('copy', save, once) // 添加一个copy事件,当触发时执行一次,执行完删除
      document.execCommand('copy') // 执行copy方法
      this.$message({ message: '复制成功', type: 'success' })
    }
  }
 }
</script>

<style lang='scss' scoped>
.block {
  .block-card {
    background: #232366;
    border: 1px solid #2ED7F1;
    position: relative;
    @media screen and (min-width: 1200px) {
      padding: 30px 30px 10px 30px;
      margin-bottom: 45px;
      // .bg-image {
      //   position: absolute;
      //   width: 325px;
      //   height: 283px;
      //   background-image: url('../../assets/blockdetails_bg.png');
      //   background-size: contain;
      //   right: 87px;
      //   top: 47px;
      // }
      >.title {
        font-size: 20px;
        font-weight: bold;
      }
      .hex-card {
        display: flex;
        padding: 15px 23px 15px 13px;
        background: linear-gradient(90deg, #D7B764, #FFF6DD);;
        border-radius: 13px;
        align-items: center;
        margin-bottom: 23px;
        margin-top: 20px;
        .label {
          font-size: 14px;
          font-weight: bold;
          color: #18184C;
          padding-right: 26px;
        }
        .value {
          font-size: 12px;
          color: #18184C;
        }
      }
      .item {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        .label {
          font-size: 14px;
          color: #ABABFF;
          width: 60px;
          padding-right: 10px;
          line-height: 14px;
        }
        .value {
          font-size: 12px;
          line-height: 12px;
        }
        .copy {
          position: relative;
          font-size: 12px;
          color: #D7B764;
          margin-left: 20px;
          padding-left: 15px;
          cursor: pointer;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 6px);
            width: 10px;
            height: 12px;
            background-image: url('../../assets/icon_copy.png');
            background-size: contain;
          }
          
        }
      }
    }
    @media screen and (max-width: 1200px) { 
      padding: 0.3rem 0.3rem 0.1rem 0.3rem;
      margin: 0 0.24rem;
      border-radius: 0.04rem;
      margin-bottom: 0.7rem;
      >.title {
        font-size: 0.32rem;
        font-weight: bold;
      }
      .hex-card {
        width: 100%;
        word-break: break-all;
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #D7B764, #FFF6DD);
        border-radius: 0.18rem;
        padding: 0.2rem 0.24rem 0.2rem 0.2rem;
        width: 6.42rem;
        box-sizing: border-box;
        margin: 0.2rem 0 0.32rem 0;
        .label {
          font-size: 0.24rem;
          font-weight: bold;
          color: #18184C;
          width: 0.5rem;
          flex-shrink: 0;
          margin-right: 0.2rem;
        }
        .value {
          font-size: 0.2rem;
          color: #18184C;
        }
      }
      .item {
        width: 100%;
        display: flex;
        // align-items: center;
        font-size: 0.2rem;
        padding-bottom: 0.2rem;
        .label {
          color: #ABABFF;
          width: 1rem;
          padding-right: 0.2rem;
          flex-shrink: 0;
          line-height: 0.24rem;
        }
        .value {
          word-break: break-all;
        }
        .copy {
          display: none;
        }
      }
    }
  }
  .info {
    @media screen and (min-width: 1200px) {
      >.title {
        font-size: 20px;
        font-weight: bold;
      }
      .info-list {
        padding-top: 31px;
        .item {
          padding-bottom: 20px;
          display: flex;
          align-items: center;
          .label {
            width: 400px;
            color: #ABABFF;
            font-size: 14px;
            padding-right: 30px;
          }
          .value {
            font-size: 12px;
          }
        }
        .goodsname {
          font-size: 14px;
          color: #ABABFF;
          padding-bottom: 40px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      padding: 0 0.24rem 0.4rem 0.24rem;
      >.title {
        font-weight: bold;
        font-size: 0.28rem;
        padding-bottom: 0.4rem;
      }
      .info-list {
        .item {
          padding-bottom: 0.23rem;
          display: flex;
          font-size: 0.2rem;
          .label {
            color: #ABABFF;
            width: 3.2rem;
            flex-shrink: 0;
            padding-right: 0.2rem;
          }
          .value {
            word-break: break-all;
          }
        }
        .goodsname {
          font-size: 0.2rem;
          color: #ABABFF;
          word-break: break-all;
        }
      }
    }
  }
}
</style>